// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/lib/es6/src/Css.js";
import * as Block from "bs-platform/lib/es6/block.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Gatsby from "gatsby";
import * as Theme$GlazedWebsite from "../../Theme.js";
import * as Gatsby$GlazedWebsite from "../../shared/Gatsby.js";
import * as Heading$GlazedWebsite from "../../Heading.js";
import * as CaseStudyPageTemplate$GlazedWebsite from "./building-blocks/CaseStudyPageTemplate.js";
import * as LogoGlazedSmallSvg from "static/images/logo-glazed-small.svg";

var inPageHeading = Curry._1(Css.style, /* :: */[
      Css.textAlign(/* center */98248149),
      /* :: */[
        Css.maxWidth(Css.rem(64)),
        /* :: */[
          Css.padding2(/* zero */-789508312, Css.rem(1.875)),
          /* :: */[
            Css.margin(/* auto */-1065951377),
            /* :: */[
              Css.color(Css.hex(Theme$GlazedWebsite.Colors.grey)),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var adHocComponent = Curry._1(Css.style, /* :: */[
      Css.textAlign(/* center */98248149),
      /* [] */0
    ]);

var Styles = {
  inPageHeading: inPageHeading,
  adHocComponent: adHocComponent
};

var pageImagesQuery = (Gatsby.graphql`
     query {
       headerImage: file(relativePath: { eq: "case-studies/farfetch-hero.jpg" }) {
         childImageSharp {
           fluid(maxWidth: 1200) {
             ...GatsbyImageSharpFluid_withWebp_noBase64
           }
         }
       }
       bigImage: file(relativePath: { eq: "case-studies/farfetch-01.jpg" }) {
         childImageSharp {
           fluid(maxWidth: 1200) {
             ...GatsbyImageSharpFluid_withWebp_noBase64
           }
         }
       }
       productImage2: file(relativePath: { eq: "case-studies/farfetch-02.png" }) {
         childImageSharp {
           fluid(maxWidth: 1200) {
             ...GatsbyImageSharpFluid_withWebp_noBase64
           }
         }
       }
       productImage4: file(relativePath: { eq: "case-studies/farfetch-03.png" }) {
         childImageSharp {
           fluid(maxWidth: 1200) {
             ...GatsbyImageSharpFluid_withWebp_noBase64
           }
         }
       }
       joseNeves: file(relativePath: { eq: "case-studies/farfetch-joseneves.jpeg" }) {
         childImageSharp {
           fluid(maxWidth: 120) {
             ...GatsbyImageSharpFluid_withWebp_noBase64
           }
         }
       }
       nextCaseImage: file(relativePath: { eq: "case-studies/mercadao-hero.jpg" }) {
         childImageSharp {
           fluid(maxWidth: 2400) {
             ...GatsbyImageSharpFluid_withWebp_noBase64
           }
         }
       }
     }
  `);

function Farfetch(Props) {
  var queryResult = Gatsby.useStaticQuery(pageImagesQuery);
  var headerImage = Gatsby$GlazedWebsite.getImageFluid(queryResult, "headerImage");
  var bigImage = Gatsby$GlazedWebsite.getImageFluid(queryResult, "bigImage");
  var productImage2 = Gatsby$GlazedWebsite.getImageFluid(queryResult, "productImage2");
  var productImage4 = Gatsby$GlazedWebsite.getImageFluid(queryResult, "productImage4");
  var nextCaseImage = Gatsby$GlazedWebsite.getImageFluid(queryResult, "nextCaseImage");
  var content_hero = {
    image: headerImage,
    title: "Farfetch",
    area: "Fashion - Ecommerce",
    text: "An international fashion website that sells products from 400 independent boutiques around the world."
  };
  var content_brief = {
    techs: [
      {
        name: "react",
        icon: LogoGlazedSmallSvg.default
      },
      {
        name: "angular",
        icon: LogoGlazedSmallSvg.default
      },
      {
        name: "ios",
        icon: LogoGlazedSmallSvg.default
      }
    ],
    year: "2019",
    brief: "Create a white label version of their main e-commerce site for big brands that want to sell on their own website."
  };
  var content_content = [
    /* BigImage */Block.__(0, [bigImage]),
    /* TextAndImage */Block.__(1, [
        "There are many ways to optimise the conversion funnel, yet the bottom line is to invite customers into the store and convert a sale with the minimum amount of friction possible.\n\nTherefore, a responsive design solution, converged in performance with search engine and app store optimisation was vital.\n\nSince SEO was an essential requirement for the type of search the company wanted to achieve, we implemented an isomorphic React storefront with webpack. This allowed us to do performance optimization via caching and mobile-specific code chunks, and a component-based single-page application that is search-engine optimised that only serves the content needed for each context, with a fast and light first-load which is great for mobile.\n\nConsidering the codebase was split across several microservices with a very scalable architecture it allowed us to easily deploy via docker.",
        productImage4
      ]),
    /* Custom */Block.__(5, [React.createElement(Heading$GlazedWebsite.make, {
              level: /* H3 */2,
              className: inPageHeading,
              children: "This project, called black-and-white, was the main strategic move the company made after being valued at 1 billion dollars."
            })]),
    /* TextAndImage */Block.__(1, [
        "As a result, Glazed created a custom eCommerce CMS, where each tenant could change the look and content of the storefront in real-time.\n\n        The multi-tenant fashion eCommerce store that could host a large feature including Product Collections, Seasons, Campaigns, Search, Browsing, Filtering, Product Recommendation algorithm, Shopping Bag, Favourites, Social interaction, Campaigns, Discounts, Newsletter, Analytics and Boutiques listing.\n\n        All of them integrated with Farfetch internal processes which provided Operations, Payments, Shipping, Billing, Returns and Support.",
        productImage2
      ])
  ];
  var content_nextCase = {
    image: nextCaseImage,
    title: "Mercadão",
    area: "Retail - Marketplace",
    link: "/case-studies/mercadao"
  };
  var content = {
    hero: content_hero,
    brief: content_brief,
    content: content_content,
    nextCase: content_nextCase
  };
  return React.createElement(CaseStudyPageTemplate$GlazedWebsite.make, {
              content: content,
              pageTitle: "Glazed • Farfetch",
              pageDescription: "An international fashion website that sells products from 400 independent boutiques around the world."
            });
}

var make = Farfetch;

var $$default = Farfetch;

export {
  Styles ,
  pageImagesQuery ,
  make ,
  $$default ,
  $$default as default,
  
}
/* inPageHeading Not a pure module */
